import React from "react";
import RequestForProposal from "../components/RequestForProposal";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import { ACTIVE_PAGE } from "../components/nav/utils";
import Nav from "../components/nav/Nav";

export default function RequestForProposalPage() {
  return (
    <>
      <Nav hidden />

      <RequestForProposal />
    </>
  );
}
